<template>
  <section class="edit-category">
    <Loader :loading="showLoader" />
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-title">
            <div class="row">
              <div class="col-12">
                <h3 class="page-title float-left">Stripe</h3>

                <router-link class="float-right" to="/settings/config-settings">
                  <i class="mdi mdi-window-close font-size-18 text-dark"></i>
                </router-link>
              </div>
            </div>
          </div>
          <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form  @submit.prevent="editStripe">
              <div class="row" v-if="info">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="Stripe">Stripe Currency</label>
                    <input type="text" v-model="info.STRIPE_CURRENCY" class="form-control"/>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="Stripe">Stripe Language</label>
                    <input type="text" v-model="info.STRIPE_LANGUAGE"
                     class="form-control"/>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="Stripe">Stripe SandBox Mode</label>
                    <input type="text" v-model="info.STRIPE_SANDBOX_MODE"
                     class="form-control"/>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="Stripe">Stripe PK Live</label>
                    <input type="text" v-model="info.STRIPE_PK_LIVE" 
                    class="form-control"/>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="Stripe">Stripe PK Text</label>
                    <input type="text" v-model="info.STRIPE_PK_TEST"
                    class="form-control"/>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="Stripe">Stripe Return URL</label>
                    <input type="text" v-model="info.STRIPE_RETURN_URL"
                     class="form-control"/>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="Stripe">Stripe SK Live	</label>
                    <input type="text" v-model="info.STRIPE_SK_LIVE"
                     class="form-control"/>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="Stripe">Stripe SK Text</label>
                    <input type="text" v-model="info.STRIPE_SK_TEST"
                    class="form-control"/>
                  </div>
                </div>
                
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="Stripe">Stripe WebHook Client Secret</label>
                    <input type="text" v-model="info.STRIPE_WEBHOOK_CLIENT_SECRET"
                     class="form-control"/>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group float-right">
                    <button class="btn btn btn-add btn-primary">Update</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { setingsApi } from "../../../api";
import Loader from "../../../components/Loader";
export default {
  name: "edit-stripe",

  components: {
    Loader,
  },
  data() {
    return {
      info: null,
      showLoader: false,
    };
  },
  mounted() {
    this.list();
  },
  methods: {
    async list() {
      this.showLoader = true;
      const { data } = await setingsApi.stripe();
      this.info = data.data.data;
      this.showLoader = false;
    },
    async editStripe() {

      for (var key in this.info) {
        if (this.info.hasOwnProperty(key)) {
          var val = this.info[key];
          if (val === null || val === "") {
            delete this.info[key];
          }
        }
      }

      this.showLoader = true;
      const data = await setingsApi.updateStripe({
        ...this.info,
      });
      this.showLoader = false;
      this.$router.push("/settings/config-settings");
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
    },
  },
};
</script>

<style scoped></style>
